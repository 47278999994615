import React from "react"
import Layout from "../components/layout"

const InfoPage = ({ pageContext }) => (
  <Layout title="Information and Links" navItems={pageContext.categories}>
    <div className="uk-section-xsmall">
      <div className="uk-container uk-container-large uk-text-left">
        <h1 className="uk-text-center">Information and Links</h1>
        <p>&nbsp;</p>
        <p>
          Main North American Daikin Information Database (
          <a
            href="https://daikincity.com"
            target="_blank"
            className="uk-text-bold"
          >
            daikincity.com
          </a>
          )
        </p>

        <p>
          Main North American Daikin consumer website (
          <a
            href="https://daikincomfort.com"
            target="_blank"
            className="uk-text-bold"
          >
            daikincomfort.com
          </a>
          )
        </p>

        <p>
          Revit library (
          <a
            href="https://bim.daikincity.com"
            target="_blank"
            className="uk-text-bold"
          >
            bim.daikincity.com
          </a>
          )
        </p>

        <p>
          Energy Modelling Information (
          <a
            href="https://daikinac.com/content/resources/software-tools/"
            target="_blank"
            className="uk-text-bold"
          >
            daikinac.com/content/resources/software-tools/
          </a>
          )
        </p>

        <p>
          R-32 Refrigerant Information (
          <a
            href="https://r32reasons.com"
            target="_blank"
            className="uk-text-bold"
          >
            r32reasons.com
          </a>
          )
        </p>

        <p>
          DXS Main Website (sample projects, tools, information) (
          <a href="https://dxseng.com" target="_blank" className="uk-text-bold">
            dxseng.com
          </a>
          )
        </p>
        <p>&nbsp;</p>
      </div>
    </div>
  </Layout>
)

export default InfoPage
